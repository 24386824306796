<template>
  <section class="static-pages">
    <v-container>
      <div class="__bread mt-5">
        <v-breadcrumbs :items="Bread"></v-breadcrumbs>
      </div>
      <div class="content">
        <h3> {{ $t('footer.privacy-policy') }} </h3>
        <hr/>
        <div class="text-start">
          <h1>سياسة الخصوصية لسين بارك</h1>
          <p class="mb-8">مرحبًا بكم في سين بارك، حيث نلتزم بحماية خصوصيتك وضمان أن تكون تجربتك معنا آمنة وسلسة.
            توضح هذه
            السياسة كيفية جمع واستخدام وحماية بياناتك الشخصية عند استخدامك منصتنا.</p>

          <h4>1. المعلومات التي نجمعها</h4>
          <p>عند استخدامك لسين بارك، قد نقوم بجمع المعلومات التالية:
            <br>
            المعلومات الشخصية: مثل الاسم، رقم الجوال، عنوان البريد الإلكتروني، وعنوانك عند التسجيل.
            <br>
            معلومات الحجز والدفع: لتسهيل إتمام الحجوزات والدفع بأمان.
            <br>
            معلومات الاستخدام: بما في ذلك تفضيلات البحث، المواقع التي تستعرضها، والأنشطة التي تقوم بها عبر سين بارك.
            <br>
            بيانات الموقع الجغرافي: عند منح الإذن بذلك، لتوفير اقتراحات أكثر دقة حول المواقع القريبة.
          </p>

          <h4>2. كيفية استخدام المعلومات</h4>
          <p>نستخدم المعلومات التي نجمعها للأغراض التالية:
            <br>
            لتسهيل عملية الحجز والتواصل بين المضيفين والمستأجرين.
            <br>
            لتحسين خدماتنا وتجربة المستخدم عبر تخصيص المحتوى والخدمات.
            <br>
            لمعالجة المدفوعات وضمان الأمان المالي.
            <br>
            لإرسال تحديثات متعلقة بالحجوزات، العروض، والخدمات الجديدة.
            <br>
            للامتثال للمتطلبات القانونية والرقابية في المملكة العربية السعودية.
          </p>

          <h4>3. مشاركة المعلومات</h4>
          <p>نحن نحترم خصوصيتك ولن نشارك بياناتك مع أي طرف ثالث دون موافقتك، باستثناء الحالات التالية:
            <br>
            عند الضرورة لإتمام الحجوزات مع مضيفي العقارات.
            <br>
            مع مزودي خدمات الدفع لضمان إتمام العمليات المالية بأمان.
            <br>
            إذا كان ذلك مطلوبًا بموجب القانون أو لحماية حقوقنا أو حقوق المستخدمين.
          </p>

          <h4>4. حماية المعلومات</h4>
          <p>نستخدم أحدث معايير التشفير وتقنيات الأمان لحماية بياناتك الشخصية من الوصول غير المصرح به، الاستخدام غير
            الصحيح، أو التعديل.</p>

          <h4>5. ملفات تعريف الارتباط (Cookies)</h4>
          <p>نستخدم ملفات تعريف الارتباط لتحسين تجربة المستخدم وتقديم خدمات مخصصة. يمكنك إدارة إعدادات ملفات تعريف
            الارتباط من خلال متصفحك.</p>

          <h4>6. حقوق المستخدمين</h4>
          <p>لديك الحق في:
            <br>
            الوصول إلى بياناتك الشخصية التي نحتفظ بها.
            <br>
            طلب تعديل أو حذف أي معلومات غير دقيقة.
            <br>
            سحب الموافقة على معالجة بياناتك الشخصية، حيثما كان ذلك ممكنًا.
          </p>

          <h4>7. تحديث سياسة الخصوصية</h4>
          <p>قد نقوم بتحديث سياسة الخصوصية من وقت لآخر. سيتم إخطار المستخدمين بالتغييرات الرئيسية عبر البريد الإلكتروني
            أو من خلال إشعار على سين بارك.
            <br>
            سين بارك لتأجير مساحات التصوير المختلفة في السعودية تقدر خصوصيتك وتسعى دائمًا لتقديم أفضل تجربة ممكنة.
            شكراً لثقتك بنا!
          </p>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      Bread: [
        {
          text: this.$t("Breadcrumb.home"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$t('footer.privacy-policy'),
          disabled: true,
          to: "",
        },
      ],
    };
  }
};
</script>

<style lang="scss" scoped>
.static-pages {
  height: 100%;
  background-color: #fafafa;
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 70px 0;
  }

  .__bread {
    box-shadow: none !important;

    .v-breadcrumbs {
      background: #ffffff 0 0 no-repeat padding-box;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      font-size: 14px;
      box-shadow: none !important;
      padding: 12px;
    }

    .v-breadcrumbs__item {
      color: #000 !important;
    }

    .v-breadcrumbs__item--disabled {
      color: #868e96 !important;
    }
  }

  .content {
    margin-top: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    h3 {
      font-weight: bold;
      margin-top: 5px;
      color: #4d4d4d;
    }

    p {
      line-height: 1.8;
      color: #4d4d4d;
      text-align: start;
    }

    hr {
      height: 5px;
      color: #e0e0e0;
      width: 100%;
    }
  }
}
</style>
