<template>
  <!-- i removed , (customNavbar: isSpecialRoute) from dynamic class to add customNavbar -->
  <v-app
      id="app"
      :class="{
      'lang-en': lang === 'en',
      customNavbar: isSpecialRoute,
      secondSpecialRoute: secondSpecialRoute,
    }"
  >
    <Navbar/>
    <router-view/>
    <Footer/>
  </v-app>
</template>
<script>
import axios from "axios";
import home from "@/views/home.vue";
import Navbar from "@/views/navbar.vue";
import Footer from "@/views/footer.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    home,
  },
  data() {
    const lang = localStorage.getItem("lang") || "en";
    return {
      lang: lang,
      userData: '',
    };
  },
  computed: {
    isSpecialRoute() {
      const path = this.$route.path;
      const sectionsRegex = /^\/sections\/\d+$/.test(path);
      return (
          path === "/cine_stocks" ||
          // path === "/spaces" ||
          path === "/professional_markets" ||
          path === "/cine_academies" ||
          path === "/camera_gear_rentals" ||
          path === "/camera_gear_purchases" ||
          path === "/sections" ||
          // path === "/" ||
          sectionsRegex
      );
    },
    secondSpecialRoute() {
      return !this.isSpecialRoute
    },
  },
  created() {
    // to still user login after refresh page
    const userSrting = localStorage.getItem("user");
    if (userSrting) {
      const userData = JSON.parse(userSrting);
      this.userData = userData;
      this.$store.commit("SET_USER_DATA", userData);
    }

    // prevent user to write fake token or userdata
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response.status === 401) {
            if (this.$route.name !== 'studiosDetails') {
              this.$store.dispatch("handleLogout", {member_id: this.userData.id});
              this.$router.push("/");
            }
          }
          return Promise.reject(error);
        }
    );
  },
  mounted() {
    // Create a script element for ads snapchat
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://sc-static.net/scevent.min.js';

    // Add an event listener to track when the script has loaded
    script.addEventListener('load', () => {
      if (typeof snaptr === 'function') {
        snaptr('init', '2ef5fcfd-d6f7-4a11-a494-a39f187ed941', {
          user_email: '_INSERT_USER_EMAIL_'
        });
        snaptr('track', 'PAGE_VIEW');
      }
    });

    // Append the script to the document's head
    document.head.appendChild(script);
  },
};
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
  }
}
</style>
