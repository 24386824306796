<template>
  <v-dialog v-model="Editdialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="btn">
        <v-icon>mdi-square-edit-outline</v-icon>
        تعديل الحجز
      </v-btn>
    </template>

    <v-card>
      <div
          class="d-flex justify-content-between align-items-center flex-row pt-3 px-2"
      >
        <span class="font-weight-bold"> تعديل الحجز </span>
        <button @click="CancelDialog = false">
          <v-icon> mdi-close</v-icon>
        </button>
      </div>
      <v-divider></v-divider>
      <div class="px-2">
        <v-row>
          <v-col cols="12" md="6">
            <div class="d-flex justify-content-start align-items-start flex-column gap-2 date_studio mt-5">
              <span> {{ $t("studios.StartDate") }} </span>
              <v-menu v-model="Startbook"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="Startdate"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                class="w-100"
                                color="#e0e0e0"
                                style="border-color: red"
                                :error-messages="StartdateErrors"
                                @input="$v.Startdate.$touch()"
                                @blur="$v.Startdate.$touch()">
                    <template #prepend-inner>
                      <v-icon color="#4D4D4D" style="margin-inline-end: 10px;">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    :locale="lang"
                    :min="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                    v-model="Startdate"
                    @input="Startbook = false"
                    @change="getDayWorkTimes($event, 'start');"
                    :allowed-dates="allowedDates"></v-date-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col cols="12" md="6" v-if="!ifHideEndDate">
            <div class="d-flex justify-content-start align-items-start flex-column gap-2 date_studio mt-5">
              <span> {{ $t("studios.endDate") }} </span>
              <v-menu v-model="Endbook"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="Enddate"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                class="w-100"
                                :error-messages="EnddateErrors"
                                @input="$v.Enddate.$touch()"
                                @blur="$v.Enddate.$touch()">
                    <template #prepend-inner>
                      <v-icon color="#4D4D4D" style="margin-inline-end: 10px;">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    :locale="lang"
                    :min="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                    v-model="Enddate"
                    @input="Endbook = false"
                    @change="getDayWorkTimes($event, 'end');"
                    :allowed-dates="allowedDates"></v-date-picker>
              </v-menu>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" :class="{'d-none': getOrderStudioDeatils.rent_type === 'day'}">
            <div class="d-flex justify-content-start align-items-start flex-column gap-2 date_studio">
              <span> {{ $t("studios.StartTime") }} </span>
              <v-select class="w-100 mt-0 pt-0"
                        item-text="text"
                        item-value="value"
                        v-model="Starttime"
                        :items="start_clock_hours"
                        :error-messages="StarttimeErrors"
                        @input="$v.Starttime.$touch()"
                        @blur="$v.Starttime.$touch()">
              </v-select>
            </div>
          </v-col>
          <v-col cols="12" md="6" :class="{'d-none': getOrderStudioDeatils.rent_type === 'day'}">
            <div class="d-flex justify-content-start align-items-start flex-column gap-2 date_studio">
              <span> {{ $t("studios.EndTime") }} </span>
              <v-select class="w-100 mt-0 pt-0"
                        item-text="text"
                        item-value="value"
                        v-model="Endtime"
                        :items="end_clock_hours"
                        :error-messages="EndtimeErrors"
                        @input="$v.Endtime.$touch()"
                        @blur="$v.Endtime.$touch()">
              </v-select>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-divider class="mt-8"></v-divider>

      <v-card-actions class="d-flex justify-content-end align-items-start flex-row px-3 actions" style="gap: 10px">
        <v-btn color="#000"
               depressed
               @click="handleUpdateStudio"
               :loading="loadingUpdate">
          تعديل الحجز
        </v-btn>
        <v-btn color="#E0E0E0" @click="Editdialog = false" depressed>
          تراجع
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {validationDateAndAhours} from "@/rules";
import axios from "axios";

export default {
  mixins: [validationDateAndAhours],
  props: {
    studio_id: {
      type: Number,
      required: true,
      default: 0
    },
    getOrderStudioDeatils: {
      type: Object,
      required: true,
      default: null
    }
  },
  data() {
    return {
      lang: localStorage.getItem('lang') || 'ar',
      Editdialog: false,
      Startbook: false,
      Endbook: false,
      loadingUpdate: false,
      start_clock_hours: [
        {text: this.$t('choose-start-date-firstly'), value: ''},
      ],
      end_clock_hours: [
        {text: this.$t('choose-end-date-firstly'), value: ''},
      ],
      Startdate: this.getOrderStudioDeatils.from_date,
      Enddate: this.getOrderStudioDeatils.to_date,
      Starttime: this.getOrderStudioDeatils.from_time_24_system,
      Endtime: this.getOrderStudioDeatils.to_time_24_system,
      getOrderStudioDeatils: null,
    };
  },
  computed: {
    ...mapGetters(["getUpdateData"]),
    updatePayload() {
      const payload = {
        order_id: this.$route.params.id,
        from_date: this.Startdate,
        to_date: this.Enddate,
        from_time: this.Starttime,
        to_time: this.Endtime,
      };
      return {...payload};
    },
    ifHideEndDate() {
      return (this.getOrderStudioDeatils.studio.rent_type === 'hour' && this.getOrderStudioDeatils.studio.hour_rent_end_date_status === 'hide');
    }
  },
  async created() {
    await this.getAnyStudioDayWorkTimes(this.getOrderStudioDeatils.from_date, 'start');
    await this.getAnyStudioDayWorkTimes(this.getOrderStudioDeatils.to_date, 'end');
  },
  methods: {
    allowedDates(val) {
      let dayOfWeek = new Date(val).getDay();
      return this.getOrderStudioDeatils.studio.available_working_days.includes(dayOfWeek);
    },
    handleUpdateStudio() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loadingUpdate = true;
      this.$store
          .dispatch("updateStudio", this.updatePayload)
          .then(() => {
            location.reload();
            this.TriggerNotify("success", this.getUpdateData);
          })
          .catch((err) => {
            this.loadingUpdate = false;
            this.TriggerNotify("error", err.response.data.message);
          });
    },
    async getDayWorkTimes(event, place) {
      this.loadingUpdate = true;
      const PAYLOAD = {
        studio_id: this.studio_id,
        date: event,
      };

      await axios.post('get-studio-day-work-times', PAYLOAD)
          .then((res) => {
            let data = res.data.data;

            // if (place === 'start') {
            //   this.start_clock_hours = data;
            // } else {
            //   this.end_clock_hours = data;
            // }

            if (place === 'start') {
              this.start_clock_hours = data;
              if (this.getOrderStudioDeatils.rent_type === 'day' && data.length > 1) {
                this.Starttime = data[1].value;
              }
              if (this.ifHideEndDate) {
                this.Enddate = event;
                this.end_clock_hours = data;
              }
            } else {
              this.end_clock_hours = data;
              if (this.getOrderStudioDeatils.rent_type === 'day' && data.length > 1) {
                let dataLength = data.length - 1;
                this.Endtime = data[dataLength].value;
              }
            }

            this.loadingUpdate = false;
          })
          .catch((e) => {
            this.loadingUpdate = false;
            this.TriggerNotify("error", e.response.data.message);
          });
    },
    async getAnyStudioDayWorkTimes(event, place) {
      this.loadingUpdate = true;
      const PAYLOAD = {
        studio_id: this.studio_id,
        date: event,
      };

      await axios.post('get-any-studio-day-work-times', PAYLOAD)
          .then((res) => {
            let data = res.data.data;

            if (place === 'start') {
              this.start_clock_hours = data;
            } else {
              this.end_clock_hours = data;
            }

            this.loadingUpdate = false;
          })
          .catch((e) => {
            this.loadingUpdate = false;
            this.TriggerNotify("error", e.response.data.message);
          });
    }
  },
};
</script>

<style scoped lang="scss">
.btn {
  background-color: #e0e0e0 !important;
  box-shadow: 0 0 1px rgba(29, 119, 177, 0.1607843137);
  border-radius: 4px;
  color: #4d4d4d;
  height: 44px !important;
  line-height: 44px;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: bold;

  i {
    margin-inline-end: 5px;
    color: #4d4d4d !important;
  }
}

.actions {
  button {
    letter-spacing: 0;

    &:first-of-type {
      color: #fff;
    }
  }
}

hr {
  padding: 0;
  margin: 8px;
  opacity: 0.5;
}
</style>
<style>
.v-date-picker-header {
  direction: initial !important;
}

.v-menu__content {
  z-index: 999 !important;
}

.v-select__selection--comma {
  color: #4d4d4d !important;
}
</style>